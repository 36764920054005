import Alpine from 'alpinejs'

import '@css/main.css';
 
window.Alpine = Alpine
 
Alpine.start()

$(document).ready(function () {
  $('.testimonails__slider').slick({
    autoplay: true,
    arrows: false,
    dots: true,
    appendDots: $('.slick-dots'),
    fade: true,
    autoplaySpeed: 5000,
    
  });
  
  $('.related-content-slider').on('init', (e, slick) => {
    $('#related-content-slide-count').html('1 of ' + slick.slideCount);
    $('#related-content-slide-count-lg').html('1 of ' + slick.slideCount);
  });

  $('.related-content-slider').on('afterChange', (e, slick) => {
    $('#related-content-slide-count').html(slick.currentSlide + 1 + ' of ' + slick.slideCount);
    $('#related-content-slide-count-lg').html(slick.currentSlide + 1 + ' of ' + slick.slideCount);
  });

  $('.related-content-slider').slick({
    autoplay: false,
    arrows: false,
    dots: false,
  });

  $('.experts-slider-mobile').on('init', (e, slick) => {
    $('#experts-slider-mobile-slide-count').html('1 of ' + slick.slideCount);
  });

  $('.experts-slider-mobile').on('afterChange', (e, slick) => {
    $('#experts-slider-mobile-slide-count').html(slick.currentSlide + 1 + ' of ' + slick.slideCount);
  });

  $('.experts-slider-mobile').slick({
    autoplay: false,
    arrows: false,
    dots: false,
  });

  $('.experts-slider-desktop').slick({
    autoplay: false,
    arrows: false,
    dots: false,
    slidesToShow: 3,
    appendDots: $('.slick-dots-expert'),
    responsive: [
      {
        breakpoint: 1096, //lg
        settings: {
          slidesToShow: 3,
          dots: true,
        }
      },
      {
        breakpoint: 1024, //lg
        settings: {
          slidesToShow: 3,
          dots: true,
        }
      },
      {
        breakpoint: 768, //md
        settings: {
          slidesToShow: 2,
          dots: true,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          dots: true,
        }
      }
    ]
  });

  //videos-slider-slide-count
  $('.videos-slider').on('init', (e, slick) => {
    $('#videos-slider-slide-count').html('1 of ' + slick.slideCount);
  });

  $('.videos-slider').on('afterChange', (e, slick) => {
    $('#videos-slider-slide-count').html(slick.currentSlide + 1 + ' of ' + slick.slideCount);
  });

  $('.videos-slider').slick({
    autoplay: false,
    arrows: false,
    dots: false,
  });

  //To be deleted once videos page is live
  $('.old-videos-slider').on('init', (e, slick) => {
    $('#videos-slider-slide-count').html('1 of ' + slick.slideCount);
  });

  //To be deleted once videos page is live
  $('.old-videos-slider').on('afterChange', (e, slick) => {
    $('#videos-slider-slide-count').html(slick.currentSlide + 1 + ' of ' + slick.slideCount);
  });

  //To be deleted once videos page is live
  $('.old-videos-slider').slick({
    autoplay: false,
    arrows: false,
    dots: false,
    slidesToShow: 3,
    appendDots: $('.slick-dots-expert'),
    responsive: [
      {
        breakpoint: 1096, //lg
        settings: {
          slidesToShow: 3,
          dots: true,
        }
      },
      {
        breakpoint: 1024, //lg
        settings: {
          slidesToShow: 3,
          dots: true,
        }
      },
      {
        breakpoint: 768, //md
        settings: {
          slidesToShow: 2,
          dots: true,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          dots: true,
        }
      }
    ]
  });

  //latest-news-slide-count
  $('.latest-news-slider').on('init', (e, slick) => {
    $('#latest-news-slide-count').html('1 of ' + slick.slideCount);
  });

  $('.latest-news-slider').on('afterChange', (e, slick) => {
    $('#latest-news-slide-count').html(slick.currentSlide + 1 + ' of ' + slick.slideCount);
  });

  $('.latest-news-slider').slick({
    autoplay: false,
    arrows: false,
    dots: false,
  });

  $('.banner_text_slider').slick({
    autoplay: true,
    arrows: false,
    dots: false,
    fade: true,
  });

  $('.scroll-up-button').click(function() {
    $("#body-container").animate({ scrollTop: 0 }, "slow");
    return false;
  });

  //disable parallax effect on mobile
  // function setParallax() {
  //   var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  //   var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
  //   if (isSafari && iOS) {
  //       alert("You are using Safari on iOS!");
  //   } else if(isSafari) {
  //       alert("You are using Safari.");
  //   }
  // }
  // setParallax();

  let player;
  // the url will be in the format https://player.vimeo.com/video/615829767
  window.showModal = function(url) {
    if (!url) {
      console.log('No url found for vimeo player');
      return;
    }
    // split the url by / and get the last entry which should be the number
    let urlSections = url.split('/');
    let id = urlSections[urlSections.length - 1];

    let height = 500;

    if ($(window).width() <= 768) {
      height = 200;
    }

    let options = {
      id,
      //width: 800,
      height,
    };

    //add the <x-gadget.modal /> to the master / index page on the site so its available everywhere
    //change the "Meet [whoever]" block's play button to something like:
    //<button onclick="window.showModal(537332969);">Open modal <i class="fas fa-play text-red ml-4"></i></button>
    //where the integer param is the ID of the vimeo video
    if (player) {
      player.destroy().then(() => {
        $('#video-modal').toggle();

        player = new Vimeo.Player('vimeo-player', options);
        player.ready().catch(e => {
          console.log('Something went wrong while loading the vimeo player, ' + e);
          console.log('The url was: ' + url);
          console.log('The id was: ' + id);
          $('#video-modal').toggle();
        })
        player.play();
      })
    }else{
      $('#video-modal').toggle();
      
      player = new Vimeo.Player('vimeo-player', options);
      player.ready().catch(e => {
        console.log('Something went wrong while loading the vimeo player, ' + e);
        console.log('The url was: ' + url);
        console.log('The id was: ' + id);
        $('#video-modal').toggle();
      })
      player.play();
    }
  }

  window.closeModal = () => {
    player.destroy();
    $('#video-modal').toggle();
  }

  $('#filter-reset').click(function(e) {
    // stop the default browser reset behaviour
    e.preventDefault();

    // find the filter form whose reset button we pressed
    var filterForm = $(e.target).parents('#filter-form');

    // clear out the value of any dropdown fields on the filter form
    filterForm.find('select').val('0');

    // clear out the value of any text input fields on the filter form
    filterForm.find('input[type=text]').val('');
  });

  $('#latest-news-left').click(function() {
    let el = document.getElementById('latest-news-wrapper');
    el.scrollLeft = el.scrollLeft - 320;
  })

  $('#latest-news-right').click(function() {
    let el = document.getElementById('latest-news-wrapper');
    el.scrollLeft = el.scrollLeft + 320;
  })

  $("#latest-filter-reset").click(function(){
    window.location.href = window.location.href.split('?')[0];
  });

});

function accordionDefaultState(defaultState) {
  if ($(window).width() <= 640) {
    // on mobiles accordions should default to closed
    return {
      open: false
    };
  } else {
    // on larger devices accordions should default to whatever state was selected
    return {
      open: defaultState
    };
  }
}

